/*----------------------------------------------------------------------------------------------
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
----------------------------------------------------------------------------------------------=*/
import {teacher_pages} from '@/modules/Teachers/paths.js'
import store from '@/store/store'

const routes = [
  ////////////////////////////
  // StudentAffairs
  ///////////////////////////
  {
    url: null,
    name: 'StudentAffairs',
    tagColor: 'warning',
    icon: 'UsersIcon',
    i18n: 'StudentAffairs.student_affairs',
    meta: {
      rule: 'TeacherSNDSMD'
    },
    submenu: [
      {
        url: '/student-affairs/all-students',
        name: 'AllStudents',
        slug: 'all-students',
        i18n: 'StudentAffairs.allStudents'
      },
      {
        url: '/student-affairs/all-parents',
        name: 'AllParents',
        slug: 'all-parents',
        i18n: 'StudentAffairs.allparents'
      }
    ]
  },

  ////////////////////////////
  // Admission
  ///////////////////////////
  {
    url: null,
    name: 'Admission',
    tagColor: 'warning',
    icon: 'UserCheckIcon',
    i18n: 'admission.Admission',
    meta: {
      rule: 'TeacherSNDSMD'
    },
    submenu: [
      {
        url: '/admission/applications',
        name: 'Applications',
        slug: 'applications',
        i18n: 'admission.Applications'
      },

      {
        url: '/admission/round-manager',
        name: 'RoundManager',
        slug: 'round-manager',
        i18n: 'admission.AdmissionManager'
      },
      {
        url: '/admission/registration',
        name: 'registration',
        slug: 'registration',
        i18n: 'admission.registration'
      }
    ]
  },

  ////////////////////////////
  // Academic
  ///////////////////////////
  {
    url: null,
    name: 'Academic',
    tagColor: 'warning',
    icon: 'AwardIcon',
    i18n: 'AcademicYears.academic',
    meta: {
      rule: 'SNDSMD'
    },
    submenu: [
      {
        url: '/academic/academic-years',
        name: 'AcademicYearsList',
        slug: 'academic-years',
        i18n: 'AcademicYears.AcademicYears'
      },
      {
        url: '/academic/Subjects',
        name: 'SubjectsList',
        slug: 'Subjects',
        i18n: 'subject.Subjects'
      },
      {
        url: '/academic/Classes',
        name: 'ClassesList',
        slug: 'classes',
        i18n: 'classes.ClassesList'
      },
      {
        url: '/academic/syllabus',
        name: 'SyllabusList',
        slug: 'syllabus',
        i18n: 'Syllabus.syllabusList'
      }
    ]
  },
  //////////////////////////
  // GradeBook
  ////////////////////////
  {
    url: null,
    name: 'grading',
    icon: 'AwardIcon',
    slug: 'grading',
    i18n: 'grading.grading_system',
    meta: {
      rule: 'SNDSMD'
    },
    submenu: [
      {
        url: '/grading/gradebook',
        name: 'gradebook',
        slug: 'gradebook',
        i18n: 'grading.gradebook',
        meta: {
          rule: 'SNDSMD'
        }
      },
      {
        url: '/grading/inventory',
        name: 'inventory',
        slug: 'inventory',
        i18n: 'basic.all_report_card',
        meta: {
          rule: 'SNDSMD'
        }
      },
      {
        url: '/grading/allocations',
        name: 'allocations',
        slug: 'allocations',
        i18n: 'grading.grading_allocations',
        meta: {
          rule: 'SND'
        }
      },
      {
        url: '/grading/rounds',
        name: 'gradingControl',
        slug: 'gradingControl',
        i18n: 'grading.the_rounds_control',
        meta: {
          rule: 'SND'
        }
      },
      {
        url: '/grading/boundaries-and-descriptors',
        name: 'boundariesAndDescriptors',
        slug: 'boundariesAndDescriptors',
        i18n: 'boundariesAndDescriptors.boundaries',
        meta: {
          rule: 'SND'
        }
      }
    ]
  },
  ////////////////////////////
  // TeacherPage
  ///////////////////////////
  {
    url: null,
    name: 'Teachers Gateway',
    tagColor: 'warning',
    icon: 'UsersIcon',
    i18n: 'teachersGateway.teachers',
    meta: {
      rule: 'SNDSMD'
    },
    submenu: [
      {
        url: '/teachers-gateway/',
        name: 'ListTeachers',
        slug: 'list-teachers',
        i18n: 'teachersGateway.list_teachers'
      }
    ]
  },

  ///////////////////////////////////////////
  /////////////// Admission attendance /////
  /////////////////////////////////////////

  ///////////////////////////////////////////
  /////////////// Admission attendance /////
  /////////////////////////////////////////

  ///////////////////////////////////////////
  /////////////// Admission attendance /////
  /////////////////////////////////////////
  {
    url: null,
    name: 'Attendance',
    tagColor: 'warning',
    icon: 'CheckCircleIcon',
    i18n: 'config.Attendance',
    meta: {
      rule: 'TeacherSNDSMD'
    },
    submenu: [
      {
        url: '/attendance/attendance-console',
        name: 'AttendanceConsole',
        slug: 'attendance-console',
        i18n: 'config.AttendanceConsole',
        meta: {
          rule: 'TeacherSND'
        }
      },
      {
        url: '/attendance/insights',
        name: 'Insights',
        slug: 'insights',
        i18n: 'config.Insights',
        meta: {
          rule: 'TeacherSNDSMD'
        }
      },
      {
        url: '/attendance/settings',
        name: 'Settings',
        slug: 'settings',
        i18n: 'config.Settings',
        meta: {
          rule: 'TeacherSND'
        }
      }
    ]
  },
  //////////////////////////////
  // Teacher
  ///////////////////////////
  teacher_pages.assignment_list,
  teacher_pages.onlineClass_list,
  teacher_pages.quickMeetingList,
  teacher_pages.repository,
  teacher_pages.students_directory,
  teacher_pages.attendance,
  teacher_pages.planner,
  teacher_pages.exam_list,
  teacher_pages.teaching_materials,
  ///////////////////////////////
  // agenda
  //////////////////////////////
  {
    url: '/teacher/agenda',
    name: 'agenda',
    icon: 'AwardIcon',
    i18n: 'agenda.agenda',
    meta: {
      rule: 'SectionsCoodinator'
    }
  },

  ////////////////////////////
  // Configuration
  ///////////////////////////
  {
    url: null,
    name: 'Configuration',
    tagColor: 'warning',
    icon: 'SettingsIcon',
    i18n: 'config.Configuration',
    meta: {
      rule: 'SNDSMDPOICoordinator'
    },
    submenu: [
      {
        url: '/system-configurations/institution-details',
        name: 'InstitutionDetails',
        slug: 'institution-details',
        i18n: 'config.InstitutionDetails',
        meta: {
          rule: 'SMD'
        }
      },
      {
        url: '/system-configurations/division-details',
        name: 'DivisionDetails',
        slug: 'division-details',
        i18n: 'config.divisions',
        meta: {
          rule: 'SND'
        }
      },
      {
        url: '/system-configurations/branch-details',
        name: 'BranchDetails',
        slug: 'branch-details',
        i18n: 'config.BranchDetails',
        meta: {
          rule: 'SMD'
        }
      },
      {
        url: '/system-configurations/poi-details',
        name: 'POI',
        slug: 'poi-details',
        i18n: 'config.POI',
        meta: {
          rule: 'SNDPOICoordinator'
        }
      },
      {
        url: '/system-configurations/section-details',
        name: 'SectionDetails',
        slug: 'section-details',
        i18n: 'config.SectionDetails',
        meta: {
          rule: 'SND'
        }
      },
      {
        url: '/system-configurations/user-management/users',
        name: 'UserManagement',
        slug: 'user-management',
        i18n: 'config.UserManagement',
        meta: {
          rule: 'SMD'
        }
      },
      {
        url: '/system-configurations/users-logs-List',
        name: 'Userslogs',
        slug: 'users-logs',
        i18n: 'usersLogs.users_logs',
        meta: {
          rule: 'SND'
        }
      }
    ]
  },

  ///////////////////////////////
  // POI
  //////////////////////////////
  {
    url: '/poi/poi-container',
    name: 'POI',
    icon: 'MessageSquareIcon',
    slug: 'poi-details',
    i18n: 'config.POI',
    meta: {
      rule: 'POISectionsCoodinator'
    }
  },
  ///////////////////////////////
  // Grading
  //////////////////////////////

  {
    url: '/grading/teacher/gradebook',
    name: 'gradebook',
    slug: 'gradebook',
    icon: 'AwardIcon',
    i18n: 'grading.gradebook',
    meta: {
      rule: 'Teacher'
    }
  },
  {
    url: '/grading/SchoolPrincipal/gradebook',
    name: 'gradebook',
    slug: 'gradebook',
    icon: 'AwardIcon',
    i18n: 'grading.gradebook',
    meta: {
      rule: 'gradebookPrincipalCoordinators'
    }
  },
  //////////////////////////////

  ///////////////////////////////
  // announcement
  //////////////////////////////
  {
    url: '/teacher/announcement',
    name: 'announcement',
    slug: 'announcement',
    icon: 'Volume2Icon',
    i18n: 'announcement.announcements',
    meta: {
      rule: 'Teacher'
    }
  },

  ///////////////////////////////
  // utilities
  //////////////////////////////

  {
    url: null,
    name: 'utilities',
    tagColor: 'warning',
    icon: 'ServerIcon',
    i18n: 'basic.utilities',
    meta: {
      rule: 'SNDSMDPOICoordinator'
    },
    submenu: [
      {
        url: '/canteen',
        name: 'Canteen',
        icon: 'ShoppingBagIcon',
        slug: 'poi-details',
        i18n: 'canteen.canteen',
        meta: {
          rule: 'TeacherSNDSMD'
        }
      },
      //////////////////////////////
      {
        url: '/wallet',
        name: 'wallet',
        icon: 'DollarSignIcon',
        slug: 'poi-details',
        i18n: 'wallet.wallet',
        meta: {
          rule: 'TeacherSNDSMD'
        }
      },
      {
        url: '/announcement',
        name: 'announcement',
        icon: 'DollarSignIcon',
        slug: 'poi-details',
        i18n: 'announcement.announcements',
        meta: {
          rule: 'TeacherSNDSMD'
        }
      },
      {
        url: '/my-day',
        name: 'myDay',
        icon: 'CalenderIcon',
        slug: 'poi-details',
        i18n: 'myDay.myDay',
        meta: {
          rule: 'TeacherSNDSMD'
        }
      }
    ]
  },

  //////////////////////////////
  // SchoolPrincipal
  /////////////////////////////
  {
    url: null,
    name: 'Overview',
    tagColor: 'warning',
    icon: 'BarChart2Icon',
    i18n: 'classes.overview',
    meta: {
      rule: 'SchoolPrincipal'
    },
    submenu: [
      {
        url: '/virtualClasses',
        name: 'basic.virtualClasses',
        tagColor: 'warning',
        icon: 'AirplayIcon',
        i18n: 'basic.onlineClass',
        meta: {
          rule: 'SchoolPrincipal'
        }
      },
      {
        url: '/assignments',
        name: 'assignment.assignments',
        tagColor: 'warning',
        icon: 'EditIcon',
        i18n: 'assignment.assignments',
        meta: {
          rule: 'SchoolPrincipal'
        }
      },
      {
        url: '/assessments',
        name: 'assessments.assessments',
        tagColor: 'warning',
        icon: 'EditIcon',
        i18n: 'basic.tasks',
        meta: {
          rule: 'SchoolPrincipal'
        }
      },
      {
        url: '/teacher-list',
        name: 'teachersGateway.list_teachers',
        tagColor: 'warning',
        icon: 'UsersIcon',
        i18n: 'teachersGateway.list_teachers',
        meta: {
          rule: 'SchoolPrincipal'
        }
      }
    ]
  }
]

//todo remove this after refactor the work around of student profile
const authUser = store.getters['auth/authUser']
if (authUser) {
  routes.unshift({
    url: `/student/profile/${authUser.student != null ? authUser.uid : 0}`,
    name: 'BackToProfile',
    tagColor: 'warning',
    icon: 'UserIcon',
    i18n: 'config.BackToProfile',
    meta: {
      rule: 'Student'
    }
  })
  ////////////////////////
  // if DP
  ////////////////////////
  const is_DP_Student = authUser.DP,
    is_student = authUser.student

  if (is_DP_Student && !is_student) {
    // CAS
    routes.push({
      url: '/cas/tasks',
      name: 'cas',
      icon: 'AwardIcon',
      i18n: 'cas.cas',
      meta: {
        rule: 'CasCoordinator'
      }
    })
  }

  ////////////////////////////////////////////////
  // if parent && not completed his profile data
  ////////////////////////////////////////////////
  const is_parent = ['parent', 'Parent'].includes(authUser.userRole)

  if (is_parent) {
    const isParentCompletedProfile = authUser.isParentCompletedProfile,
      isParentResponsible = authUser.isParentResponsible,
      completedProfileRoute = {
        url: '/parent/completeProfileData',
        name: 'completeProfileData',
        icon: 'UserIcon',
        i18n: 'completeProfileData.completeProfileData',

        meta: {
          rule: 'Parent'
        }
      },
      announcement = {
        url: '/parent/announcement',
        name: 'announcements',
        icon: 'UserIcon',
        i18n: 'announcement.announcements',
        meta: {
          rule: 'Parent'
        }
      },
      completedCustodyRoute = {
        url: '/parent/completeCustodyData',
        name: 'completeChildrenData',
        icon: 'UsersIcon',
        i18n: 'completeProfileData.completeChildrenData',
        meta: {
          rule: 'Parent'
        }
      },
      profileRoute = {
        url: '/parent/profile/',
        name: 'parentProfile',
        icon: 'UserIcon',
        i18n: 'basic.parent',
        meta: {
          rule: 'Parent'
        }
      }
    if (isParentCompletedProfile) routes.push(profileRoute)
    else routes.push(completedProfileRoute)
    routes.push(announcement)
    if (isParentResponsible) routes.push(completedCustodyRoute)
  }
  ////////////////////////////
  // Grade
  ///////////////////////////
}

export default routes
