<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template >
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <p class="text-capitalize">
      <span>{{ $t("basic.COPYRIGHT") }} &copy;</span>
      <span>{{ new Date().getFullYear() }}</span
      >&nbsp;
      <span class="text-primary">{{ siteTitle }}</span>
      <span class="hidden sm:inline-block"
        >,{{ $t("basic.all_rights_reserved") }}</span
      >
    </p>
    <span class="md:flex hidden items-center text-capitalize">
      <span class="text-capitalize">
        {{ $t("basic.software_Designed_and_operated_by") }}
        <!-- <a :href="appLink" target="_blank" rel="nofollow">{{ appName }}</a> -->
        {{ appName }}
        {{ $t("basic.with") }}
      </span>
      <feather-icon
        icon="HeartIcon"
        svgClasses="stroke-current text-danger w-6 h-6"
        class="ml-2"
      />
    </span>
  </footer>
</template>

<script>

export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String
    },
    copyrighName:{type:String},
    copyrighLink:{type:String}

  }


}
</script>
